@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css"); 
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');



body {
    /* background-image: url('./assets/images/chess-bg.svg') !important; */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'Open Sans', sans-serif;
    --neon-text-color: #f40;
  --neon-border-color: #08f;

}

.heading {
    font-family: 'La Belle Aurore', cursive;
    font-weight: 400;
    font-size: xxx-large;
}

#hand {
    animation:tilt-n-move-shaking 2.1s 0.6s infinite;
  transform-origin: 75% 75%;
  display: inline-block;
}

  @keyframes tilt-n-move-shaking {
    0% {
        transform: rotate(0deg);
      }
      10% {
        transform: rotate(16deg);
      }
      20% {
        transform: rotate(-6deg);
      }
      30% {
        transform: rotate(16deg);
      }
      40% {
        transform: rotate(-4deg);
      }
      50% {
        transform: rotate(16deg);
      }
      60% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(0deg);
      }
  }



.circles{
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.01);
    animation: animate 25s linear infinite;
    top: -100px;    
}

.fallingSquare:hover {
    background: white;
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(1000px) rotate(720deg);
        opacity: 0;
        border-radius: 0%;
    }

}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.about-link {
  text-decoration: underline;
}

.project-image {
  z-index: 1;
  transition: transform .2s;


}

.project-image:hover {
  filter: saturate(1.2);
  transform: scale(1.05);    
}

#tsparticles {
  z-index: -10;
}


.ascii-text {

  text-orientation: sideways;
     
}



